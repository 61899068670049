import COMMENT_QUERY from "gql/comment"
import { handleGraphQLMutation } from "services"

const COMMENT_SERVICE = {
    getCommentFeed: ({ body }) => handleGraphQLMutation({
        schema: COMMENT_QUERY.GET_COMMENT,
        body,
    }),
    addAndUpdateReactionComment: ({ body }) => handleGraphQLMutation({
        schema: COMMENT_QUERY.ADD_AND_UPDATE_REACTION_COMMENT,
        body,
    }),
    deleteReactionComment: ({ body }) => handleGraphQLMutation({
        schema: COMMENT_QUERY.DELETE_REACTION_COMMENT,
        body,
    }),
    createCommentFeed: ({ body }) => handleGraphQLMutation({
        schema: COMMENT_QUERY.CREATE_COMMENT_FEED,
        body,
    }),
    editCommentFeed: ({ body }) => handleGraphQLMutation({
        schema: COMMENT_QUERY.EDIT_COMMENT_FEED,
        body,
    }),
    deleteComment: ({ body }) => handleGraphQLMutation({
        schema: COMMENT_QUERY.DELETE_COMMENT,
        body,
    }),
}

export default COMMENT_SERVICE