import FEED_QUERY from 'gql/feed';
import { handleGraphQLMutation } from 'services';

const FEED_SERVICE = {
    getAdsById: ({ body }) => handleGraphQLMutation({
        schema: FEED_QUERY.GET_FEED_LIST,
        body,
    }),
    addReactionFeed: ({ body }) => handleGraphQLMutation({
        schema: FEED_QUERY.ADD_REACTION_FEED,
        body,
    }),
    deleteReactionFeed: ({ body }) => handleGraphQLMutation({
        schema: FEED_QUERY.DELETE_REACTION_FEED,
        body,
    }),
    updateReactionFeed: ({ body }) => handleGraphQLMutation({
        schema: FEED_QUERY.UPDATE_REACTION_FEED,
        body,
    }),
    updatePrivacyFeed: ({ body }) => handleGraphQLMutation({
        schema: FEED_QUERY.UPDATE_PRIVACY_FEED,
        body,
    }),
    deleteFeed: ({ body }) => handleGraphQLMutation({
        schema: FEED_QUERY.DELETE_FEED,
        body,
    }),
}

export default FEED_SERVICE