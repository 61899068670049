import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query"
import ADS_SERVICE from "services/refactor/ads"
import { QUERY_KEYS } from "../queryKeys";

// ============================================================
// ADS QUERIES
// ============================================================
export const useDeleteFavorites = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return ADS_SERVICE.deleteFavorites({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}

export const useAddFavorites = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return ADS_SERVICE.addFavorites({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}

export const useGetAdsById = (adsId = '') => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_ADS_BY_ID, adsId],
        queryFn: () => {
            return ADS_SERVICE.getAdsById({
                body: {
                    id: adsId
                }
            })
        },
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
    })
}

export const useCreateContactAds = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return ADS_SERVICE.createContactAds({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}

export const useGetListAds = ({ pageSize = 5, filter, sort }) => {
    return useInfiniteQuery({
        queryKey: [QUERY_KEYS.GET_LIST_JOB_ADS],
        queryFn: async ({ pageParam = 1 }) => {
            const response = await ADS_SERVICE.getListAds({
                body: {
                    currentPage: pageParam,
                    pageSize,
                    filter,
                    sort
                }
            })
            return response
        },
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage && lastPage.data && lastPage.data.page_info) {
                const { current_page, total_page } = lastPage.data.page_info;
                if (current_page < total_page) {
                    return current_page + 1;
                }
            }
            return null;
        },
        onSuccess: (data) => {
            return data;
        },
        cacheTime: 0,
        staleTime: 0,
        keepPreviousData: false
    })
}