


// ============================================================
// VOTE QUERIES
// ============================================================

import { useInfiniteQuery } from "react-query"
import { QUERY_KEYS } from "../queryKeys"
import VOTE_SERVIE from "services/refactor/vote"

export const useGetListVote = ({ customerId = '', pageSize = 5, status }) => {
    return useInfiniteQuery({
        queryKey: [QUERY_KEYS.GET_LIST_VOTE, customerId],
        queryFn: async ({ pageParam = 1 }) => {
            const response = await VOTE_SERVIE.getListVote({
                body: {
                    currentPage: pageParam,
                    customer_id: customerId,
                    pageSize,
                    status
                }
            })
            return response
        },
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage && lastPage.data && lastPage.data.page_info) {
                const { current_page, total_page } = lastPage.data.page_info;
                if (current_page < total_page) {
                    return current_page + 1;
                }
            }
            return null;
        },
        onSuccess: (data) => {
            return data;
        },
        cacheTime: 0,
        staleTime: 0,
        keepPreviousData: false
    })
}