import React from 'react';

const CheckDownLineCotent = (text) => {
    const newArr = text?.split('\n');
    let indexArr = [];
    newArr?.forEach((mes, idx) => {
        if (mes !== '') {
            indexArr = [...indexArr, idx];
        }
    });

    return indexArr?.length > 3;
};

const NewlineText = (text) => {
    return text?.split('\n').map((str, idx) => {
        const parts = str.split(' ');
        if (str)
            return (
                <p key={idx}>
                    {parts.map((part, index) => {
                        const isLink = /^https?:\/\//.test(part);

                        if (isLink) {
                            return (
                                <a className="a__original text-[#3b82f6]" target="__blank" key={index} href={part}>
                                    {part}
                                </a>
                            );
                        } else {
                            return part + ' ';
                        }
                    })}
                </p>
            );
        else return <br key={idx} />;
    });
};

export { NewlineText, CheckDownLineCotent };
