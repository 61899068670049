const { default: VOTE_QUERY } = require("gql/vote");
const { handleGraphQLMutation } = require("services");

const VOTE_SERVIE = {
    getListVote: ({ body }) => handleGraphQLMutation({
        schema: VOTE_QUERY.GET_LIST_VOTE,
        body,
    }),
}

export default VOTE_SERVIE