import {
    gql
} from '@apollo/client'
import { extractQueryInfo } from 'gql'

const GET_COMMENT_FEED_QUERY = gql`
    query getCommentFeed($feedId: String, $pageSize: Int, $currentPage: Int){
        getCommentFeed (feedId: $feedId, pageSize: $pageSize, currentPage: $currentPage) { 
            page_info {
                current_page
                page_size
                total_page
            }
            items {
                comment_id
                status
                comment
                parent_comment_id
                customer_id
                firstname
                lastname
                avatar
                admin_id
                url_key
                created_at
                updated_at
                comments_child {
                    comment_id
                    status
                    comment
                    parent_comment_id
                    customer_id
                    firstname
                    lastname
                    url_key
                    avatar
                    admin_id
                    total_reaction
                    created_at
                    updated_at
                }
            }
        }
    }
`

const ADD_AND_UPDATE_REACTION_COMMENT_QUERT = gql`
    mutation reactionComment($commentId: Int!, $type: Int!){
        #1: LOVE, 2: LIKE, 3: CONGRATS, 4: WOW, 5: SAD, 6: HAHA, 7: ANGRY
        reactionComment (
            comment_id: $commentId,
            type: $type
        ) {
            result
        }
    }
`

const DELETE_REACTION_COMMENT_QUERY = gql`
    mutation unReactionComment($commentId: Int!) {
        unReactionComment (
            comment_id: $commentId
        ) {
            result
        }
    }
`

const CREATE_COMMENT_FEED_QUERY = gql`
    mutation createCommentFeed($input: CommentFeedInput!){
        createCommentFeed(
            # Type input: CommentFeedInput
            input: $input
        ) {
            status
            item {
                  comment_id
                status
                comment
                parent_comment_id
                customer_id
                firstname
                lastname
                avatar
                admin_id
                url_key
                created_at
                updated_at
                comments_child {
                    comment_id
                    status
                    comment
                    parent_comment_id
                    customer_id
                    firstname
                    lastname
                    url_key
                    avatar
                    admin_id
                    total_reaction
                    created_at
                    updated_at
                }
                customer {
                    id
                    avatar
                    firstname
                    lastname
                }
            }
        }
    }
`

const EDIT_COMMENT_FEED_QUERY = gql`
    mutation editCommentFeed($input: EditCommentFeedInput!){
        editCommentFeed(
            # Type input: EditCommentFeedInput
            input: $input
        ) {
            item {
                comment_id
                status
                comment
                parent_comment_id
                customer_id
                admin_id
                created_at
                updated_at
            }
        }
    }
`

const DELETE_COMMENT_QUERY = gql`
    mutation deleteComment($id: String!) {
        deleteComment(id: $id) {
            result
        }
    }
`


const COMMENT_QUERY = {
    GET_COMMENT: extractQueryInfo(GET_COMMENT_FEED_QUERY),
    ADD_AND_UPDATE_REACTION_COMMENT: extractQueryInfo(ADD_AND_UPDATE_REACTION_COMMENT_QUERT),
    DELETE_REACTION_COMMENT: extractQueryInfo(DELETE_REACTION_COMMENT_QUERY),
    CREATE_COMMENT_FEED: extractQueryInfo(CREATE_COMMENT_FEED_QUERY),
    EDIT_COMMENT_FEED: extractQueryInfo(EDIT_COMMENT_FEED_QUERY),
    DELETE_COMMENT: extractQueryInfo(DELETE_COMMENT_QUERY)
}


export default COMMENT_QUERY