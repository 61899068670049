import {
    gql
} from '@apollo/client'
import { extractQueryInfo } from 'gql'

const GET_LIST_VOTE_QUERY = gql`
    query getVoteList($pageSize: Int!, $currentPage: Int!, $status: [String], $customerId: String) {
        getVoteList(
            pageSize: $pageSize
            currentPage: $currentPage
            status: $status
            customer_id: $customerId
        ) {
            items {
                id
                name
                status
                description
                application_start_time
                application_end_time
                voting_start_time
                voting_end_time
                banner
                total_apply
                created_at
                updated_at
                url_key
                nailsmaster_apply {
                    id
                    firstname
                    lastname
                    avatar
                    url_key
                    position
                    total_vote
                }
                is_apply_vote
                user_voted_list
            }
            page_info  {
                page_size
                current_page
                total_page
            }
        }
    }

`
const VOTE_QUERY = {
    GET_LIST_VOTE: extractQueryInfo(GET_LIST_VOTE_QUERY)
}

export default VOTE_QUERY