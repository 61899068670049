import { useInfiniteQuery, useMutation } from "react-query"
import { QUERY_KEYS } from "../queryKeys"
import COMMENT_SERVICE from "services/refactor/comment";

// ============================================================
// COMMENT QUERIES
// ============================================================
export const useGetCommentFeed = ({ feedId = '', pageSize = 5 }) => {
    return useInfiniteQuery({
        queryKey: [QUERY_KEYS.GET_COMMENT_FEED, feedId],
        queryFn: async ({ pageParam = 1 }) => {

            const response = await COMMENT_SERVICE.getCommentFeed({
                body: {
                    currentPage: pageParam,
                    feedId,
                    pageSize
                }
            });
            return response;
        },
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage && lastPage.data && lastPage.data.page_info) {
                const { current_page, total_page } = lastPage.data.page_info;
                if (current_page < total_page) {
                    return current_page + 1;
                }
            }
            return null;
        },
        onSuccess: (data) => {
            return data;
        },
        cacheTime: 0,
        staleTime: 0,
        keepPreviousData: false
    })
}

export const useAddAndUpdateReactionComment = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return COMMENT_SERVICE.addAndUpdateReactionComment({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}

export const useDeleteReactionComment = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return COMMENT_SERVICE.deleteReactionComment({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}

export const useCreateCommentFeed = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return COMMENT_SERVICE.createCommentFeed({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}

export const useEditCommentFeed = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return COMMENT_SERVICE.editCommentFeed({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}

export const useDeleteComment = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return COMMENT_SERVICE.deleteComment({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}