import { useInfiniteQuery, useMutation, useQuery } from "react-query"
import { QUERY_KEYS } from "../queryKeys"
import FEED_SERVICE from "services/refactor/feed"

// ============================================================
// FEED QUERIES
// ============================================================
export const useGetFeedList = ({ customerId = '', pageSize = 5 }) => {
    return useInfiniteQuery({
        queryKey: [QUERY_KEYS.GET_FEED, customerId],
        queryFn: async ({ pageParam = 1 }) => {
            // if (!customerId) {
            //     return [];
            // }
            const response = await FEED_SERVICE.getAdsById({
                body: {
                    currentPage: pageParam,
                    customerId,
                    pageSize
                }
            });
            return response;
        },
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage && lastPage.data && lastPage.data.page_info) {
                const { current_page, total_page } = lastPage.data.page_info;
                // console.log("Current Page:", current_page, "Total Page:", total_page);
                if (current_page < total_page) {
                    return current_page + 1;
                }
            }
            return null;
        },
        onSuccess: (data) => {
            return data;
        },
        cacheTime: 0,
        staleTime: 0,
        keepPreviousData: false
    })
}

export const useAddReactionFeed = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return FEED_SERVICE.addReactionFeed({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}

export const useDeleteReactionFeed = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return FEED_SERVICE.deleteReactionFeed({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}

export const useUpdateReactionFeed = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return FEED_SERVICE.updateReactionFeed({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}


export const useUpdatePrivacyFeed = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return FEED_SERVICE.updatePrivacyFeed({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}

export const useDeleteFeed = () => {
    return useMutation({
        mutationFn: ({ body }) => {
            return FEED_SERVICE.deleteFeed({ body })
        },
        onSuccess: (data) => {
            return data
        }
    })
}